import React from "react"

import { Link } from "gatsby"

export default function Intro({ data }) {
  return (
    <div id="intro" className="c-intro">
      <div className="u-inner">
        <div
          className="c-intro__content"
          dangerouslySetInnerHTML={{ __html: data }}
        />
        <div className="c-intro__action">
          <Link to="/over-ons" className="c-plus-link">
            Lees meer
          </Link>
        </div>
      </div>
    </div>
  )
}
