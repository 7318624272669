import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function Trumps({ full }) {
  const { data } = useStaticQuery(graphql`
    {
      data: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "trump" } } }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            frontmatter {
              id
              title
              icon {
                publicURL
              }
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div className="c-trumps">
      <div className="u-inner">
        <div className="o-grid o-grid--large o-grid--spaced-large@portable">
          {data.edges.map(({ node }) => {
            return (
              <div
                key={node.frontmatter.id}
                id={node.frontmatter.id}
                className="o-grid__cell u-1/1 u-1/3@desktop"
              >
                <div className={`c-trump ${!full && `c-trump--toggle`}`}>
                  <h2 className="c-trump__title">{node.frontmatter.title}</h2>
                  <div className="c-trump__image">
                    <img
                      src={node.frontmatter.icon.publicURL}
                      alt={node.frontmatter.title}
                    />
                  </div>
                  <div
                    className="c-trump__content"
                    dangerouslySetInnerHTML={{ __html: node.html }}
                  />
                  {!full && (
                    <div className="c-trump__action">
                      <Link
                        to={`/over-ons#${node.frontmatter.id}`}
                        className="c-trump__link"
                      >
                        Meer info
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
