import React from "react"

import Img from "gatsby-image"

import logo from "../img/aximet-inverted-logo.svg"
import Nav from "./Nav"
import { Link } from "gatsby"
import MenuButton from "./MenuButton"

const Hero = ({ data }) => {
  return (
    <div className="c-hero">
      <div className="c-hero__image">
        <Img
          style={{ width: "100%", height: "100%" }}
          fluid={data[0].image.childImageSharp.fluid}
        />
      </div>

      <div className="c-hero__inner">
        <div className="c-hero__top">
          <Link to="/" className="c-page-logo">
            <img src={logo} width="208" height="32" alt="Aximet" />
          </Link>

          <MenuButton />

          <Nav inverted />
        </div>

        <h1 className="c-hero__baseline">
          Las- en
          <br />
          constructie-
          <br />
          werken
        </h1>

        <a href="#intro" data-scroll className="c-hero__scroll">
          Scroll down
        </a>
      </div>
    </div>
  )
}

export default Hero
