import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Intro from "../components/Intro"
import Trumps from "../components/Trumps"
import LatestProjects from "../components/LatestProjects"

const IndexPage = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: markdownRemark(frontmatter: { id: { eq: "home" } }) {
        html
        ...SEO
        frontmatter {
          title
          formattedTitle: formatted_title
          hero {
            image {
              childImageSharp {
                fluid(maxWidth: 2160, maxHeight: 1111, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    frontmatter: { title, hero, ogImage },
    html,
  } = data

  return (
    <>
      <SEO title={title} ogImage={ogImage} />

      <Layout noHeader={true}>
        <Hero data={hero} />
        <Intro data={html} />
        <Trumps />
        <LatestProjects />
      </Layout>
    </>
  )
}

export default IndexPage
